<script lang="ts">
  import classnames from 'classnames';
  import { createEventDispatcher } from 'svelte';
  // Utils
  import { dateFullMonthFormat } from '$lib/utils/formatter';
  import { getURLFromContentfulAsset } from '$lib/utils/assets-utils';
  import { EventUtils } from '$lib/utils/event-utils';
  import { lazyLoader} from '$lib/utils/lazyloader'
  // Components
  import { Icon } from '$lib/components/atoms/Icon';
  import { Button } from '$lib/components/atoms/Button';
  // Contentful
	import type { IInitiatives } from '$lib/utils/contentful/types';
  
  export let lazyLoad: boolean = false;
  export let event: IInitiatives;
  export let showBookingButton: boolean = false;
  export let loading: boolean = false;

  const dispatch = createEventDispatcher();

  const onBooking = (event: MouseEvent) => {
    event.stopPropagation();
    //console.log('BOOKING EVENT');
    dispatch('booking');
  };

  $: iconName = <string>(
    (event.fields.mode === 'Live Streaming' ? 'live-streaming' : 'place')
  );

  $: isFullDay = event.fields.timeSlotType === 'Intera giornata' ? true : false;
</script>

<section class={classnames('event-card', $$props.class)}>
  <div class="event-image-wrapper">
    <div class="event-image">
      {#if lazyLoad}
        <img
          class="image"
          use:lazyLoader={getURLFromContentfulAsset(event.fields.thumbnailImage)}
          alt={event.fields.thumbnailImage && event.fields.thumbnailImage.fields.description ? event.fields.thumbnailImage.fields.description : ''}
        />
      {:else}
      <img
        class="image"
        src={getURLFromContentfulAsset(event.fields.thumbnailImage)}
        alt={event.fields.thumbnailImage && event.fields.thumbnailImage.fields.description ? event.fields.thumbnailImage.fields.description : ''}
      />
      {/if}
    </div>
  </div>
  <div class="event-content">
    <div class="event-info">
      {#if event.fields.type}
        <p class="event-type">{EventUtils.initiativeTypeEvent(event.fields.type)}</p>
      {/if}
      <a class="event-link" href="/eventi/{event.fields.slug}">
        <h3 class="event-title">{event.fields.title}</h3>
      </a>
      <!-- {#if event.abstract}
        <p class="event-abstract">{event.abstract}</p>
      {/if} -->

      <ul class="event-data">
        <li class="event-data-item">
          <span class="data-icon">
            <Icon icon="calendar" />
          </span>
          <span class="data-text">
            {dateFullMonthFormat(event.fields.date)}
          </span>
        </li>
        {#if !isFullDay}
          <li class="event-data-item">
            <span class="data-icon">
              <Icon icon="schedule" />
            </span>
            <span class="data-text">
              {event.fields.timeSlotFrom} | {event.fields.timeSlotTo}
            </span>
          </li>
        {/if}

        <li class="event-data-item">
          <span class="data-icon">
            <Icon icon={iconName} />
          </span>
          <span class="data-text">
            {event.fields.mode}
          </span>
        </li>
      </ul>
    </div>

    {#if showBookingButton && event.fields.bookingConfig && event.fields.bookingConfig.enabled}
      <div class="booking-button">
        <Button fullWidth on:click={onBooking} isLoading={loading}
          >{event.fields.CTAText ? 'CONFERMA' : 'Iscriviti'}</Button
        >
      </div>
    {/if}
  </div>
</section>

<style type="text/scss" lang="scss" scoped>
  .booking-button {
    margin-top: 2rem;
    z-index: 10;

    @screen md {
      margin-left: auto;
    }
  }
  .event-card {
    @apply grid;
    @apply relative;
    @apply w-full h-full;
    @apply rounded;
    @apply shadow-card-inset;
    @apply bg-white;

    grid-template-rows: auto 1fr;

    /* Using pseudo elements for hover effects on box-shadow
      is much better for performance
      See: https://tobiasahlin.com/blog/how-to-animate-box-shadow/ */

    &:before,
    &:after {
      @apply duration-500 transition-opacity ease-in-out;
    }

    /* Default State */
    &:before {
      content: '';
      @apply rounded;
      @apply absolute inset-0;
      @apply shadow-card opacity-100;
    }

    &:hover:before {
      @apply opacity-0;
    }

    /* Hover State */
    &:after {
      content: '';
      @apply rounded;
      @apply absolute inset-0;
      @apply shadow-card-hover opacity-0;
    }
    &:hover:after {
      @apply opacity-100;
    }

    &:hover {
      & .image {
        @apply transform scale-105;
      }
    }
  }

  .event-image {
    @apply overflow-hidden;
    @apply rounded-t;
    @apply aspect-w-5 aspect-h-3;
  }

  .image {
    @apply object-cover;
    @apply transition-transform duration-500 ease-in-out;
    will-change: transform;
  }

  .event-content {
    @apply overflow-hidden;
    @apply p-6;
    @apply h-full;
    @apply flex flex-col;
  }
  .event-info {
    @apply flex-grow;
  }

  .event-type {
    @apply uppercase tracking-wide font-semibold;
    @apply text-gray-600 text-sm md:text-base;
  }

  .event-type + .event-link {
    @apply mt-4;
  }

  .event-title {
    @apply text-md md:text-3xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight;
  }

  .event-link {
    @apply inline-block;
    @apply text-gray-900 font-medium font-serif;

    &:after {
      content: '';
      @apply absolute inset-0;
      @apply z-10;
    }
  }

  .event-data {
    /* Space between list items */
    --space-x: theme('spacing.6'); /* 24px */
    --space-y: theme('spacing.4'); /* 16px */

    @apply flex flex-wrap;
    @apply pt-6;
    /* Trick to re-create flex-gap for browsers that don't support it */
    margin-left: calc(var(--space-x) * -1);
    margin-top: calc(var(--space-y) * -1);
  }

  .event-data-item {
    @apply flex items-center;
    @apply uppercase tracking-wide text-xs md:text-sm text-gray-900;
    margin-left: var(--space-x);
    margin-top: var(--space-y);
  }

  .data-icon {
    @apply inline-flex flex-shrink-0 mr-2;
  }

  .data-text {
    @apply flex-shrink-0;
  }

  .event-abstract {
    @apply text-sm md:text-base;
    @apply overflow-hidden;
    @apply hidden;

    @screen md {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }

  .event-link + .event-abstract {
    @apply md:mt-6;
  }
</style>
